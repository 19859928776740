var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"inventory-list"}},[_c('v-card',{staticClass:"mb-6"},[_c('v-card-title',[_vm._v(" Vaccine Allocation ")]),_c('v-divider',{staticClass:"mt-0"}),_c('v-card-text',{staticClass:"d-flex align-center flex-wrap pb-0"},[_c('v-text-field',{staticClass:"user-search me-3 mb-4",attrs:{"placeholder":"Search","outlined":"","hide-details":"","dense":""},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}}),_c('v-spacer'),_c('div',{staticClass:"d-flex align-center flex-wrap"},[_c('v-btn',{staticClass:"mb-4",attrs:{"color":"secondary","rounded":"","outlined":""}},[_c('v-icon',{staticClass:"me-1",attrs:{"size":"17"}},[_vm._v(" "+_vm._s(_vm.icons.mdiExportVariant)+" ")]),_c('span',[_vm._v("Export")])],1)],1)],1),_c('v-data-table',{attrs:{"headers":_vm.tableColumns,"items":_vm.inventoryListTable,"options":_vm.options,"server-items-length":_vm.totalInventoryListTable,"loading":_vm.loading,"footer-props":{
        'items-per-page-options': [10, 25, 50, 100]
      },"items-per-page":25,"show-select":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.facility",fn:function(ref){
      var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('v-avatar',{class:item.facility.icon ? '' : 'v-avatar-light-bg primary--text',attrs:{"color":item.facility.icon ? '' : 'primary',"size":"32"}},[(item.facility.icon)?_c('v-img',{attrs:{"src":require(("@/assets/images/facilities/" + (item.facility.icon)))}}):_c('span',{staticClass:"font-weight-medium"},[_vm._v(_vm._s(_vm.avatarText(item.facility.name)))])],1),_c('div',{staticClass:"d-flex flex-column ms-3"},[_c('router-link',{staticClass:"text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none",attrs:{"to":{ name : 'facility-view', params : { id : item.facility.id } }}},[_vm._v(" "+_vm._s(item.facility.name)+" ")]),_c('small',[_vm._v(_vm._s(item.facility.location))])],1)],1)]}},{key:"item.date",fn:function(ref){
      var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatToDate(item.created_at)))])]}},{key:"item.received",fn:function(ref){
      var item = ref.item;
return [_c('div',{staticClass:"text-center font-weight-semibold"},[_vm._v(_vm._s(_vm.formatToAccounting(item.quantity_received)))])]}},{key:"item.issued",fn:function(ref){
      var item = ref.item;
return [_c('div',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.formatToAccounting(item.quantity_used)))])]}},{key:"item.returned",fn:function(ref){
      var item = ref.item;
return [_c('div',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.formatToAccounting(item.quantity_returned)))])]}},{key:"item.available",fn:function(ref){
      var item = ref.item;
return [_c('div',{staticClass:"text-center font-weight-semibold"},[_vm._v(_vm._s(_vm.formatToAccounting(item.quantity_holding)))])]}},{key:"item.progress",fn:function(ref){
      var item = ref.item;
return [_c('vue-apex-charts',{attrs:{"type":"radialBar","options":_vm.getChartConfig(item),"series":[_vm.resolveProgressValue(item)],"height":"90","width":"60"}})]}},{key:"item.actions",fn:function(ref){
      var item = ref.item;
return [_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-btn',{attrs:{"outlined":"","rounded":"","small":"","color":"info","to":{ name : 'facility-allocation-view', params : { id : item.id } }}},[_vm._v(" view ")])]}}],null,true)})]}}],null,true),model:{value:(_vm.selectedRows),callback:function ($$v) {_vm.selectedRows=$$v},expression:"selectedRows"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }