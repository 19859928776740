import store from '@/store'
import { ref, watch } from '@vue/composition-api'

export default function useInventoryList() {
  const inventoryListTable = ref([])

  const tableColumns = [
    {
      text: 'FACILITY',
      value: 'facility',
    },
    { text: 'VACCINE', value: 'vaccine.name', sortable: false },
    { text: 'BATCH', value: 'batch_no', sortable: false },
    { text: 'ISSUED ON', value: 'date', sortable: false },
    { text: 'RECEIVED', value: 'received', sortable: false },
    { text: 'ISSUED', value: 'issued', sortable: false },
    { text: 'RETURNED', value: 'returned', sortable: false },
    { text: 'AVAILABLE', value: 'available', sortable: false },
    { text: 'PROGRESS', value: 'progress', sortable: false },
    { text: 'ACTION', value: 'actions', sortable: false },
  ]

  const chartOptions = {
    chart: {
      sparkline: {
        enabled: false,
      },
    },
    plotOptions: {
      radialBar: {
        hollow: {
          size: '30%',
        },
        track: {
          background: '#ebe9f1',
        },
        dataLabels: {
          show: false,
          name: {
            show: false,
          },
        },
      },
    },

    grid: {
      padding: {
        bottom: -15,
      },
    },
  }

  const getChartConfig = item => {
    const options = JSON.parse(JSON.stringify(chartOptions))
    options.colors = [resolveProgressColor(item)]

    return options
  }

  const resolveProgressValue = item => {
    var received = item.quantity_received ?? 0
    var available = item.quantity_holding ?? 0

    if (available == 0) return 99
    if (received == available) return 1
    return Math.round(((received - available) / received) * 100)
  }

  const resolveProgressColor = item => {
    var progress = resolveProgressValue(item)

    if (progress <= 25) return '#ff4c51'
    if (progress > 25 && progress <= 50) return '#ffb400'
    if (progress > 50 && progress <= 75) return '#9155fd'
    if (progress > 75) return '#56ca00'

    return '#656971'
  }

  const searchQuery = ref('')
  const totalInventoryListTable = ref(0)
  const loading = ref(false)
  const options = ref({
    sortBy: ['name'],
    sortDesc: [true],
  })
  const selectedRows = ref([])

  //fetch data
  const fetchInventories = () => {
    store
      .dispatch('inventory/fetchInventories', {
        q: searchQuery.value,
        options: options.value,
      })
      .then(response => {
        inventoryListTable.value = response.data['result']
        totalInventoryListTable.value = 19
        //totalInventoryListTable.value = response.data['result'].length

        loading.value = false
      })
      .catch(error => {
        console.log(error)
      })
  }

  watch([searchQuery, options], () => {
    loading.value = true
    selectedRows.value = []
    fetchInventories()
  })

  return {
    inventoryListTable,
    tableColumns,
    searchQuery,
    totalInventoryListTable,
    loading,
    options,
    selectedRows,
    fetchInventories,
    getChartConfig,
    resolveProgressValue,
  }
}
