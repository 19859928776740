<template>
  <div id="inventory-list">
    <v-card
      class="mb-6"
    >
      <v-card-title>
        Vaccine Allocation
      </v-card-title>
      <v-divider class="mt-0"></v-divider>

      <!-- actions -->
      <v-card-text
        class="d-flex align-center flex-wrap pb-0"
      >
        <!-- search -->
        <v-text-field
          v-model="searchQuery"
          placeholder="Search"
          outlined
          hide-details
          dense
          class="user-search me-3 mb-4"
        >
        </v-text-field>
        <v-spacer></v-spacer>

        <div class="d-flex align-center flex-wrap">
          <v-btn
            color="secondary"
            rounded
            outlined
            class="mb-4"
          >
            <v-icon
              size="17"
              class="me-1"
            >
              {{ icons.mdiExportVariant }}
            </v-icon>
            <span>Export</span>
          </v-btn>
        </div>
      </v-card-text>

      <!-- table -->
      <v-data-table
        v-model="selectedRows"
        :headers="tableColumns"
        :items="inventoryListTable"
        :options.sync="options"
        :server-items-length="totalInventoryListTable"
        :loading="loading"
        :footer-props="{
          'items-per-page-options': [10, 25, 50, 100]
        }"
        :items-per-page="25"
        show-select
      >
        <!-- name -->
        <template #[`item.facility`]="{item}">
          <div class="d-flex align-center">
            <v-avatar
              :color="item.facility.icon ? '' : 'primary'"
              :class="item.facility.icon ? '' : 'v-avatar-light-bg primary--text'"
              size="32"
            >
              <v-img
                v-if="item.facility.icon"
                :src="require(`@/assets/images/facilities/${item.facility.icon}`)"
              ></v-img>
              <span
                v-else
                class="font-weight-medium"
              >{{ avatarText(item.facility.name) }}</span>
            </v-avatar>

            <div class="d-flex flex-column ms-3">
              <router-link
                :to="{ name : 'facility-view', params : { id : item.facility.id } }"
                class="text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none"
              >
                {{ item.facility.name }}
              </router-link>
              <small>{{item.facility.location}}</small>
            </div>
          </div>
        </template>

        <template #[`item.date`]="{item}">
          <span>{{ formatToDate(item.created_at) }}</span>
        </template>

        <template #[`item.received`]="{item}">
          <div
            class="text-center font-weight-semibold"
          >{{ formatToAccounting(item.quantity_received) }}</div>
        </template>

        <template #[`item.issued`]="{item}">
          <div
            class="text-center"
          >{{ formatToAccounting(item.quantity_used) }}</div>
        </template>

        <template #[`item.returned`]="{item}">
          <div
            class="text-center"
          >{{ formatToAccounting(item.quantity_returned) }}</div>
        </template>

        <template #[`item.available`]="{item}">
          <div
            class="text-center font-weight-semibold"
          >{{ formatToAccounting(item.quantity_holding) }}</div>
        </template>

        <template #[`item.progress`]="{item}">
          <vue-apex-charts
            type="radialBar"
            :options="getChartConfig(item)"
            :series="[resolveProgressValue(item)]"
            height="90"
            width="60"
          ></vue-apex-charts>
        </template>

        <!-- actions -->
        <template #[`item.actions`]="{item}">
          <v-menu
            bottom
            left
          >
            <!-- @click.stop="isEditUserSidebarActive = !isEditUserSidebarActive" -->
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                outlined
                rounded
                small
                color="info"
                :to="{ name : 'facility-allocation-view', params : { id : item.id } }"
              >
                view
              </v-btn>
            </template>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>
<script>
import { mdiExportVariant, mdiDotsVertical, mdiFormatListBulletedSquare } from '@mdi/js'
import { onUnmounted, ref } from '@vue/composition-api'
import { avatarText, formatToDate, formatToAccounting } from '@core/utils/filter'

import store from '@/store'
import storeModule from '../StoreModule'
import useInventoryList from './useInventoryList'

import VueApexCharts from 'vue-apexcharts'

export default {
  components: { VueApexCharts },
  mixins: [],
  setup() {
    const INVENTORY_STORE_MODULE_NAME = 'inventory'
    if (!store.hasModule(INVENTORY_STORE_MODULE_NAME)) store.registerModule(INVENTORY_STORE_MODULE_NAME, storeModule)

    const {
      inventoryListTable,
      tableColumns,
      searchQuery,
      totalInventoryListTable,
      loading,
      options,
      selectedRows,
      fetchInventories,
      getChartConfig,
      resolveProgressValue,
    } = useInventoryList()

    onUnmounted(() => {
      if (store.hasModule(INVENTORY_STORE_MODULE_NAME)) store.unregisterModule(INVENTORY_STORE_MODULE_NAME)
    })

    return {
      inventoryListTable,
      tableColumns,
      searchQuery,
      totalInventoryListTable,
      loading,
      options,
      selectedRows,
      icons: {
        mdiExportVariant,
        mdiDotsVertical,
        mdiFormatListBulletedSquare,
      },
      avatarText,
      formatToDate,
      formatToAccounting,
      fetchInventories,
      getChartConfig,
      resolveProgressValue,
    }
  },
}
</script>
